import React, { useEffect, useState } from 'react'
import { DefaultButton, DefaultFullWidthButton } from '../../components/Buttons'
import hdl from '../../assets/other/hdl.png'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import logoImage from '../../assets/branding/logo.png'
import axios from 'axios'
import { Input, SignUpForm } from '../../models/SignUpForm'
import ReactGA from "react-ga4";

export default function FormHomePage() {
  
  const navigate = useNavigate();
  const { passParamId } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [passId, setPassId] = useState<string>("");

  const [logo, setLogo] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  const [status, setStatus] = useState<'active' | 'draft' | ''>("");

  const [error, setError] = useState<string | null>("");

  const [authentication, setAuthentication] = useState<{ label: string, validator: string | null }>();
  const [secondaryAuthentication, setSecondaryAuthentication] = useState<{ label: string, validator: string | null }>();
  const [accountInfoRequiredInputs, setAccountInfoRequiredInputs] = useState<string[]>([]);

  const [inputs, setInputs] = useState<Input[]>([]);

  const [uploadImageRequired, setUploadImageRequired] = useState<boolean>(false);

  useEffect(() => {
  
    ReactGA.send("View Issuing Page");
    

    if (passParamId) {

      // load form
      loadFormFunction();
    }

  
  }, [])

  const loadFormFunction = () => {

    axios.get(process.env.REACT_APP_API_URL + `issuing/get-form/${passParamId}`, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      }
    }).then(response => {
        const form: SignUpForm = response.data.form;

        console.log('form', form)

        setPassId(form.passId);
        setLogo(form.logoImage);
        setCompanyName(form.companyName);
        setStatus(form.templateStatus);
        setTitle(form.title);
        setInputs(form.inputs.map(input => {
          return {
            id: input.id,
            type: input.type,
            required: input.required,
            value: "",
            label: input.label,
            placeholder: input.placeholder,
            options: input.options
          }
        }));
        setAuthentication(form.authentication)
        if (form.secondaryAuthentication) {
          setSecondaryAuthentication(form.secondaryAuthentication);
        }
        setAccountInfoRequiredInputs(form.accountInfoInputs);
        setUploadImageRequired(form.customerImage === true ? true : false);

        setIsLoading(false);

        ReactGA.event({
          category: "issuing",
          action: "form-loaded"
        });
  
    }).catch(error => {
      try {

        ReactGA.event({
          category: "issuing",
          action: "form-load-failed"
        });
        
        alert(error.data.message)
      }
      catch(error) {
        alert('Something went wrong')
      }
    })

  }

  return (
    <>
      <main>
        {/* full screen container with image background tailwind */}
        <div className="relative overflow-hidden w-screen min-h-screen bg-gradient-to-r from-violet-500 to-red-400">
          <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
            <div className="mx-4 w-full h-auto bg-white px-5 pb-11 pt-11 text-center rounded-3xl flex flex-col items-center justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
              {
                isLoading ? (
                  <div className="flex flex-col items-center justify-center">
                    <div className="w-20 h-20 rounded-full bg-gray-200 animate-pulse"></div>
                    <div className="w-20 h-4 mt-2 rounded-full bg-gray-200 animate-pulse"></div>
                    <p className="font-sans text-sm text-gray-400 mt-4">Loading...</p>
                  </div>
                ) : (
                  <>
                    {
                      logo ?
                      <img src={logo} alt="logo" className="h-16 mb-5" /> : null
                    }
                    <h1 className="font-sans font-bold text-3xl mb-5">{ title.toUpperCase() }.</h1>
                    <p className="font-sans text-sm text-gray-400">Sign up for { title } to receive exclusive dicounts, deals and marketing communications.</p>
                    <DefaultFullWidthButton
                      className="mt-7 w-full"
                      onPress={() => navigate('account-info', {
                        state: {
                          stateLogo: logo,
                          stateCompanyName: companyName,
                          stateTitle: title,
                          stateStatus: status,
                          stateInputs: inputs,
                          stateAuthentication: authentication,
                          stateSecondaryAuthentication: secondaryAuthentication,
                          stateAccountInfoInputs: accountInfoRequiredInputs,
                          stateUploadImageRequired: uploadImageRequired,
                        }
                      })}
                      text="Continue"
                    />
                    <div className="flex flex-row justify-center items-center mt-7">
                      <p className="text-gray-400 text-xs">Powered by</p>
                      <a href="https://walletpass.co.uk" target="_blank">
                        <img
                          src={logoImage}
                          alt="Wallet Pass"
                          className="h-5 ml-2"
                        />
                      </a>
                    </div>
                  </> 
                )
              }
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
