export function validateInput(fieldType:
  | 'Title'
  | 'First Name'
  | 'Last Name'
  | 'Phone'
  | 'Date of Birth'
  | 'Date'
  | 'Company Name'
  | 'Email'
  | 'Address'
  | 'Text'
  | 'Number'
  | 'Dropdown'
  , input: any, required: boolean) {

  if (fieldType === 'First Name' || fieldType === 'Last Name' || fieldType === 'Phone') {

    if (required === true && input === "") {
      return false
    }

    return true

  } else if (fieldType === 'Email') {

    if (input === "") {
      return false
    }

    if (validateEmail(input) === false) {
      return false
    }

    return true;
    

  } else if (fieldType === 'Date of Birth') {

    if (input === "") {
      return false
    }

    // is date today or in the future

    if (new Date(input) > new Date()) {
      return false;
    }

    return true;


  } else {
    if (required === true && input === "") {
      return false
    }

    return true
  }
}

export function validateEmail(email: string) 
{
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}