import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import logoImage from '../../assets/branding/logo.png'

export default function FormCompletePage() {
  const navigate = useNavigate();
  const { passParamId } = useParams();
  const { state } = useLocation();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [logo, setLogo] = useState('');

  useEffect(() => {

    if (!passParamId) {
      navigate('/');
    }

    if (!state) {
      navigate(`/passes/${passParamId}`);
    } else {

      const { email, logo } = state as any;
  
      if (!email || !logo) {
        navigate(`/passes/${passParamId}`);
      }
  
      setEmail(email);
      setLogo(logo);
  
      setIsLoading(false);
    }

    
  }, [])
  

  return (
    <>
      <main>
        {/* full screen container with image background tailwind */}
        <div className="relative overflow-hidden w-screen min-h-screen bg-gradient-to-r from-violet-500 to-red-400">
          <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
            <div className="mx-4 w-full h-auto bg-white px-5 pb-11 pt-11 text-center rounded-3xl flex flex-col items-center justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
              {
                  isLoading ? (
                    <div className="flex flex-col items-center justify-center">
                      <div className="w-20 h-20 rounded-full bg-gray-200 animate-pulse"></div>
                      <div className="w-20 h-4 mt-2 rounded-full bg-gray-200 animate-pulse"></div>
                      <p className="font-sans text-sm text-gray-400 mt-4">Loading...</p>
                    </div>
                  ) : (
                    <>
                      {
                        logo ?
                        <img src={logo} alt="logo" className="h-16 mb-5" /> : null
                      }
                      <h1 className="font-sans font-bold text-3xl mb-5">CHECK YOUR INBOX.</h1>
                      <p className="font-sans text-sm text-gray-400">We have sent an email to {email} with a link to download your pass!</p>
                      <div className="flex flex-row justify-center items-center mt-7">
                        <p className="text-gray-400 text-xs">Powered by</p>
                        <a href="https://walletpass.co.uk" target="_blank">
                          <img
                            src={logoImage}
                            alt="Wallet Pass"
                            className="h-5 ml-2"
                          />
                        </a>
                      </div>
                    </>
                  )
                }
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
