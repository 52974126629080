import axios from 'axios'
import { createRef, useEffect, useRef, useState } from 'react'
import ReactGA from "react-ga4"
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import logoImage from '../../assets/branding/logo.png'
import { DefaultFullWidthButton } from '../../components/Buttons'
import { DateInput, DefaultInput, FileInput, NumberInput } from '../../components/Inputs'
import { DefaultSelect } from '../../components/Selects'
import { validateInput } from '../../lib/validateInput'
import { Input } from '../../models/SignUpForm'

export default function FormUploadImagePage() {

  const navigate = useNavigate();
  const { passParamId } = useParams();

  const { state } = useLocation();

  const fileRef = useRef(null)

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {

    if (!passParamId) {
      navigate('/');
    }

    if (!state) {
      navigate(`/passes/${passParamId}`);
    } else {

      ReactGA.event({
        category: "issuing",
        action: "view-additional-info-form"
      });

      const {
        stateAuthentication,
        stateCompanyName,
        stateInputs,
        stateLogo,
        stateSecondaryAuthentication,
        stateStatus,
        stateTitle,
        stateAccountInfoInputs,
        stateAuthenticationInputs,
        stateAdditionalQuestionsInputs,
        stateUploadImageRequired
      } = state as any;
  
      setLogo(stateLogo);
  
      if (!stateInputs) {
        navigate(`/passes/${passParamId}`);
      }

      setInputs(stateInputs);
  
      if (!stateAccountInfoInputs) {
        navigate(`/passes/${passParamId}`);
      }
  
      setAccountInfoInputs(stateAccountInfoInputs);
  
      if (!stateAuthenticationInputs) {
        navigate(`/passes/${passParamId}`);
      }
  
      setAuthenticationInputs(stateAuthenticationInputs);

      if (!stateTitle) {
        navigate(`/passes/${passParamId}`);
      }

      setTitle(stateTitle);
  
      setIsLoading(false);
    }

    
  }, [])
  
  const [inputs, setInputs] = useState<Input[]>([])
  const [logo, setLogo] = useState<string>('')
  const [authenticationInputs, setAuthenticationInputs] = useState<{ emailAddress: string, secondaryEmailAddress: string }>({
    emailAddress: '',
    secondaryEmailAddress: ''
  })
  const [accountInfoInputs, setAccountInfoInputs] = useState<{ firstName: string, lastName: string, dateOfBirth: string, companyName: string, phoneNumber: string, title: string }>({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    companyName: '',
    phoneNumber: '',
    title: '',
  })

  const [policiesCheckbox, setPoliciesCheckbox] = useState<"checked" | "disabled">("disabled");

  const [title, setTitle] = useState<string>('');

  const [uploadImageFile, setUploadImageFile] = useState<File | null>(null);

  const areInputsValid = () => {
    let invalidInputs = []

    inputs.forEach(input => {
      if (validateInput(input.type, input.value, input.required) === false) {
        invalidInputs.push(input)
      }
    })

    if (invalidInputs.length > 0) {
      return false;
    }

    if (policiesCheckbox !== "checked") {
      
      return false;
    }

    if (uploadImageFile === null) {
      return false;
    }

    return true;
  }

  const signUpFunction = () => {
    if (!areInputsValid()) {
      alert('Please fill out all required fields');
      return;
    }

    setSubmitting(true);

    const mappedFields = inputs.map(input => {
      return {
        id: input.id,
        type: input.type,
        label: input.label,
        value: input.value,
      }
    })

    const body = {
      passId: passParamId,
      authentication: {
        emailAddress: authenticationInputs.emailAddress,
        secondaryEmailAddress: authenticationInputs.secondaryEmailAddress,
      },
      accountInfo: {
        firstName: accountInfoInputs.firstName,
        lastName: accountInfoInputs.lastName,
        dateOfBirth: accountInfoInputs.dateOfBirth,
        companyName: accountInfoInputs.companyName,
        phoneNumber: accountInfoInputs.phoneNumber,
        title: accountInfoInputs.title,
      },
      formFields: mappedFields,
      policiesAccepted: true,
      imageUpload: true,
    }

    axios.post(process.env.REACT_APP_API_URL + 'issuing/customer-sign-up', body, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      }
    }).then(async response => {

      const { customerImageUrl } = response.data;

      ReactGA.event({
        category: "issuing",
        action: "sign-up-submitted"
      });

      // upload image

      if (customerImageUrl) {

        await axios.put(customerImageUrl, uploadImageFile, {
          headers: {
            'Content-Type': 'image/png',
            'Allow-Control-Allow-Origin': '*',
          }
        }).then(() => {

          setSubmitting(false);
          setSuccess(true);
          navigate('/passes/' + passParamId + '/sign-up-success', {
            state: {
              email: authenticationInputs.emailAddress,
              logo: logo,
            }
          });
        }).catch(error => {
          console.log('error: ', error)
          setSubmitting(false);
          setSuccess(false);

          alert('Something went wrong while uploading your image.');
        })


      } else {

        setSubmitting(false);
        setSuccess(true);

        navigate('/passes/' + passParamId + '/sign-up-success', {
          state: {
            email: authenticationInputs.emailAddress,
            logo: logo,
          }
        });
      }


    }).catch(error => {

      setSubmitting(false)
      try {

        ReactGA.event({
          category: "issuing",
          action: "sign-up-failed"
        });
        
        alert(error.response.data.message)
      }
      catch(error) {
        alert('Something went wrong')
      }
    })
  }

  return (
    <>
      <main>
        {/* full screen container with image background tailwind */}
        <div className="relative overflow-hidden w-screen min-h-screen bg-gradient-to-r from-violet-500 to-red-400">
          <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
            <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-11 pt-11 text-center rounded-none sm:rounded-3xl flex flex-col items-center justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
              {
                isLoading ? (
                  <div className="flex flex-col items-center justify-center">
                    <div className="w-20 h-20 rounded-full bg-gray-200 animate-pulse"></div>
                    <div className="w-20 h-4 mt-2 rounded-full bg-gray-200 animate-pulse"></div>
                    <p className="font-sans text-sm text-gray-400 mt-4">Loading...</p>
                  </div>
                ) : (
                  <>
                  {
                    logo ?
                    <img src={logo} alt="logo" className="h-16 mb-5" /> : null
                  }
                  <h1 className="font-sans font-bold text-3xl mb-3">UPLOAD IMAGE.</h1>
                  <p className="font-sans text-sm text-gray-400 mb-7">Upload a photo of yourself for your ID.</p>
                  {/* <Link onClick={() => { (fileRef.current as any).click() }} className="h-40 w-full my-5 border-4 border-dashed border-gray-300 rounded-lg flex items-center justify-center hover:opacity-70 hover:cursor-pointer" to={''}>
                    <h3 className="text-2xl text-gray-300 font-semibold">Upload Image +</h3>
                    <input type={'file'} className="hidden" id='selectImage' onChange={() => {}} ref={fileRef as any} />
                  </Link> */}
                  <FileInput label="Profile Image" accept="image/*" onChange={(change:any) => {
                      if (change.target.files[0].size > 5000000) {
                        alert("File is too big");
                        return;
                      }
                  
                      setUploadImageFile(change.target.files[0]);
                    }} />
                  <div className="form-check mb-5 flex flex-row">
                    <input
                      className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-gray-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      id="flexCheckDefault"
                      onChange={(change: any) => {
                        setPoliciesCheckbox(
                          change.target.checked === true ? "checked" : "disabled"
                        );
                      }}
                    />
                    <label className="form-check-label inline-block font-sans text-sm text-gray-400 text-left">
                      I accept the{" "}
                      <a href="https://walletpass.co.uk/terms-conditions" target="_blank" className="underline">
                        terms & conditions
                      </a>{" "}
                      and{" "}
                      <a href="https://walletpass.co.uk/privacy-policy" target="_blank" className="underline">
                        privacy policies
                      </a>
                      .
                    </label>
                  </div>
                  <DefaultFullWidthButton
                    className="mt-5 w-full"
                    onPress={signUpFunction}
                    text={ submitting ? "Submitting..." : "Finish Sign Up"}
                    disabled={submitting}
                  />
                  <div className="flex flex-row justify-center items-center mt-7">
                    <p className="text-gray-400 text-xs">Powered by</p>
                    <a href="https://walletpass.co.uk" target="_blank">
                      <img
                        src={logoImage}
                        alt="Wallet Pass"
                        className="h-5 ml-2"
                      />
                    </a>
                  </div>
                  </>
                )
              }
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
