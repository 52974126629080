import axios from 'axios'
import { useEffect, useState } from 'react'
import ReactGA from "react-ga4"
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import logoImage from '../../assets/branding/logo.png'
import { DefaultFullWidthButton } from '../../components/Buttons'
import { DateInput, DefaultInput, NumberInput } from '../../components/Inputs'
import { DefaultSelect } from '../../components/Selects'
import { validateInput } from '../../lib/validateInput'
import { Input } from '../../models/SignUpForm'

export default function FormAdditionalQuestionsPage() {

  const navigate = useNavigate();
  const { passParamId } = useParams();

  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {

    if (!passParamId) {
      navigate('/');
    }

    if (!state) {
      navigate(`/passes/${passParamId}`);
    } else {

      ReactGA.event({
        category: "issuing",
        action: "view-additional-info-form"
      });

      const {
        stateAuthentication,
        stateCompanyName,
        stateInputs,
        stateLogo,
        stateSecondaryAuthentication,
        stateStatus,
        stateTitle,
        stateAccountInfoInputs,
        stateAuthenticationInputs,
        stateUploadImageRequired
      } = state as any;
  
      setLogo(stateLogo);
  
      if (!stateInputs) {
        navigate(`/passes/${passParamId}`);
      }

      setInputs(stateInputs);
  
      if (!stateAccountInfoInputs) {
        navigate(`/passes/${passParamId}`);
      }
  
      setAccountInfoInputs(stateAccountInfoInputs);
  
      if (!stateAuthenticationInputs) {
        navigate(`/passes/${passParamId}`);
      }
  
      setAuthenticationInputs(stateAuthenticationInputs);

      if (!stateTitle) {
        navigate(`/passes/${passParamId}`);
      }

      setTitle(stateTitle);

      if (stateUploadImageRequired) {
        setUploadImageRequired(stateUploadImageRequired);
      }
  
      setIsLoading(false);
    }

    
  }, [])
  
  const [inputs, setInputs] = useState<Input[]>([])
  const [logo, setLogo] = useState<string>('')
  const [authenticationInputs, setAuthenticationInputs] = useState<{ emailAddress: string, secondaryEmailAddress: string }>({
    emailAddress: '',
    secondaryEmailAddress: ''
  })
  const [accountInfoInputs, setAccountInfoInputs] = useState<{ firstName: string, lastName: string, dateOfBirth: string, companyName: string, phoneNumber: string, title: string }>({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    companyName: '',
    phoneNumber: '',
    title: '',
  })

  const [policiesCheckbox, setPoliciesCheckbox] = useState<"checked" | "disabled">("disabled");

  const [uploadImageRequired, setUploadImageRequired] = useState<boolean>(false);

  const [title, setTitle] = useState<string>('');

  const areInputsValid = () => {
    let invalidInputs = []

    inputs.forEach(input => {
      if (validateInput(input.type, input.value, input.required) === false) {
        invalidInputs.push(input)
      }
    })

    if (invalidInputs.length > 0) {
      console.log('invalid inputs')
      return false;
    }

    if (!uploadImageRequired && policiesCheckbox !== "checked") {
      console.log('upload image not required')
      return false;
    }

    return true;
  }

  const signUpFunction = () => {
    if (!areInputsValid()) {
      alert('Please fill out all required fields');
      return;
    }

    setSubmitting(true);

    const mappedFields = inputs.map(input => {
      return {
        id: input.id,
        type: input.type,
        label: input.label,
        value: input.value,
      }
    })

    if (uploadImageRequired) {

      // Go to upload image page

      navigate(`/passes/${passParamId}/upload-image`, {
        state: {
          stateAuthentication: authenticationInputs,
          stateInputs: inputs,
          stateLogo: logo,
          stateTitle: title,
          stateAccountInfoInputs: accountInfoInputs,
          stateAuthenticationInputs: authenticationInputs,
          stateAdditionalQuestions: mappedFields,
        }
      });

    } else {

      const body = {
        passId: passParamId,
        authentication: {
          emailAddress: authenticationInputs.emailAddress,
          secondaryEmailAddress: authenticationInputs.secondaryEmailAddress,
        },
        accountInfo: {
          firstName: accountInfoInputs.firstName,
          lastName: accountInfoInputs.lastName,
          dateOfBirth: accountInfoInputs.dateOfBirth,
          companyName: accountInfoInputs.companyName,
          phoneNumber: accountInfoInputs.phoneNumber,
          title: accountInfoInputs.title,
        },
        formFields: mappedFields,
        policiesAccepted: true,
        imageUpload: true,
      }
  
      axios.post(process.env.REACT_APP_API_URL + 'issuing/customer-sign-up', body, {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        }
      }).then(response => {
  
        setSubmitting(false);
  
        ReactGA.event({
          category: "issuing",
          action: "sign-up-submitted"
        });
  
        navigate('/passes/' + passParamId + '/sign-up-success', {
          state: {
            email: authenticationInputs.emailAddress,
            logo: logo,
          }
        });
  
  
      }).catch(error => {
  
        setSubmitting(false)
        try {
  
          ReactGA.event({
            category: "issuing",
            action: "sign-up-failed"
          });
          
          alert(error.response.data.message)
        }
        catch(error) {
          alert('Something went wrong')
        }
      })
    }

  }

  return (
    <>
      <main>
        {/* full screen container with image background tailwind */}
        <div className="relative overflow-hidden w-screen min-h-screen bg-gradient-to-r from-violet-500 to-red-400">
          <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
            <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-11 pt-11 text-center rounded-none sm:rounded-3xl flex flex-col items-center justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
              {
                isLoading ? (
                  <div className="flex flex-col items-center justify-center">
                    <div className="w-20 h-20 rounded-full bg-gray-200 animate-pulse"></div>
                    <div className="w-20 h-4 mt-2 rounded-full bg-gray-200 animate-pulse"></div>
                    <p className="font-sans text-sm text-gray-400 mt-4">Loading...</p>
                  </div>
                ) : (
                  <>
                  {
                    logo ?
                    <img src={logo} alt="logo" className="h-16 mb-5" /> : null
                  }
                  <h1 className="font-sans font-bold text-3xl mb-7">LAST QUESTIONS.</h1>
                  {inputs.map((input: Input, index: number) => {
                    if (
                      input.type === 'Text'
                    ) {
                      return (
                        <DefaultInput
                          key={index}
                          className="w-full"
                          label={input.label + (input.required ? "*" : "")}
                          placeholder={input.placeholder ? input.placeholder : `Enter your ${input.label.toLowerCase()}`}
                          onChangeText={(change: string) =>
                            setInputs(prev => {
                              prev[index].value = change;
                              return [...prev];
                            })
                          }
                        />
                      );
                    } else if (input.type === "Date") {
                      return (
                        <DateInput
                          key={index}
                          className="w-full"
                          label={input.label + (input.required ? "*" : "")}
                          placeholder={input.placeholder ? input.placeholder : 'DD/MM/YYYY'}
                          onChangeText={(change: string) =>
                            setInputs(prev => {
                              prev[index].value = change;
                              return [...prev];
                            })
                          }
                        />
                      );
                    } else if (input.type === 'Number') {
                      return (
                        <NumberInput 
                          key={index}
                          className="w-full"
                          label={input.label + (input.required ? "*" : "")}
                          placeholder={input.placeholder ? input.placeholder : `Enter your ${input.label.toLowerCase()}`}
                          onChangeText={(change: string) =>
                            setInputs(prev => {
                              prev[index].value = change;
                              return [...prev];
                            })
                          }
                        />
                      )
                    } else if (input.type === 'Dropdown' && input.options) {
                      return (
                        <DefaultSelect
                          key={index}
                          className="w-full"
                          label={input.label + (input.required ? "*" : "")}
                          placeholder={input.placeholder ? input.placeholder : `Enter your ${input.label.toLowerCase()}`}
                          options={input.options}
                          onSelect={(change: string) =>
                            setInputs(prev => {
                              prev[index].value = change;
                              return [...prev];
                            })
                          }
                        />
                      )
                    }
                  })}
                  <div className="form-check mb-5 flex flex-row">
                    <input
                      className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-gray-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      id="flexCheckDefault"
                      onChange={(change: any) => {
                        setPoliciesCheckbox(
                          change.target.checked === true ? "checked" : "disabled"
                        );
                      }}
                    />
                    <label className="form-check-label inline-block font-sans text-sm text-gray-400 text-left">
                      I accept the{" "}
                      <a href="https://walletpass.co.uk/terms-conditions" target="_blank" className="underline">
                        terms & conditions
                      </a>{" "}
                      and{" "}
                      <a href="https://walletpass.co.uk/privacy-policy" target="_blank" className="underline">
                        privacy policies
                      </a>
                      .
                    </label>
                  </div>
                  <DefaultFullWidthButton
                    className="mt-5 w-full"
                    onPress={signUpFunction}
                    text={ submitting ? "Submitting..." : "Finish Sign Up"}
                    disabled={submitting}
                  />
                  <div className="flex flex-row justify-center items-center mt-7">
                    <p className="text-gray-400 text-xs">Powered by</p>
                    <a href="https://walletpass.co.uk" target="_blank">
                      <img
                        src={logoImage}
                        alt="Wallet Pass"
                        className="h-5 ml-2"
                      />
                    </a>
                  </div>
                  </>
                )
              }
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
