import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DefaultFullWidthButton } from "../components/Buttons";
import { DefaultInput, TelephoneInput, DateInput, NumberInput, UrlInput, EmailInput } from "../components/Inputs";
import { validateEmail, validateInput } from "../lib/validateInput";
import ReactGA from "react-ga4";
import DownloadFromApple from "../assets/other/download-from-apple.svg";
import DownloadFromGoogle from "../assets/other/download-from-google.svg";
import logoImage from '../assets/branding/logo.png';



interface Pass {
  title: string,
  logo: string | null,
  companyName: string,
  templateStatus: "active" | "draft"
}

interface Customer {
  id: string,
  firstName: string,
  email: string
}

export default function DownloadPage() {

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get("token");

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [logo, setLogo] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [status, setStatus] = useState<"active" | "draft">("active");

  const [pleaseWait, setPleaseWait] = useState<boolean>(false);
  
  const [customer, setCustomer] = useState<Customer>({
    id: "",
    firstName: "",
    email: ""
  })

      
  useEffect(() => {
    
    if (token) {
      ReactGA.send("View Download Page");

      // load form
      loadDownloadContent();
    } else {
      navigate("/");
    }

  
  }, [])

  const loadDownloadContent = () => {

    axios.get(process.env.REACT_APP_API_URL + `issuing/get-download-page?token=${token}`, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      }
    }).then(response => {
        
        const data: {
          pass: Pass,
          customer: Customer
        } = response.data;

        setLogo(data.pass.logo || "");
        setCompanyName(data.pass.companyName);
        setStatus(data.pass.templateStatus);
        setTitle(data.pass.title);

        setCustomer({
          id: data.customer.id,
          firstName: data.customer.firstName,
          email: data.customer.email
        })

        setSuccess(true);

        setIsLoading(false);

        ReactGA.event({
          category: "issuing",
          action: "download-page-loaded"
        });
  
    }).catch(error => {
      try {

        ReactGA.event({
          category: "issuing",
          action: "download-page-load-failed"
        });

        setSuccess(false);
        setIsLoading(false);
      }
      catch(error) {
        setSuccess(false);
      }
    })

  }

  const downloadApple = () => {
    ReactGA.event({
      category: "issuing",
      action: "download-apple"
    });

    if (pleaseWait === true) {
      return;
    }

    setPleaseWait(true);

    axios.get(process.env.REACT_APP_API_URL + `issuing/get-apple-url?token=${token}`).then(response => {

      const data = response.data;

      setPleaseWait(false);

      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error("Something went wrong");
        
      }
    }).catch(error => {
      try {
        setPleaseWait(false);
        alert(error.data.message)
      }
      catch(error) {
        alert('Something went wrong')
      }
    })

  }

  const downloadGoogle = () => {
    ReactGA.event({
      category: "issuing",
      action: "download-google"
    });

    if (pleaseWait === true) {
      return;
    }

    setPleaseWait(true);

    axios.get(process.env.REACT_APP_API_URL + `issuing/get-google-url?token=${token}`, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      }
    }).then(response => {

      const data = response.data;

      setPleaseWait(false);

      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error("Something went wrong");
        
      }
    }).catch(error => {
      try {
        setPleaseWait(false);
        alert(error.data.message)
      }
      catch(error) {
        alert('Something went wrong')
      }
    })

  }

  return (
    <>
    <main>
      {/* full screen container with image background tailwind */}
      <div className="relative overflow-hidden w-screen min-h-screen bg-gradient-to-r from-violet-500 to-red-400">
        <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
          <div className="mx-4 w-full h-auto bg-white px-5 pb-11 pt-11 text-center rounded-3xl flex flex-col items-center justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
            {
              isLoading ? (
                <div className="flex flex-col items-center justify-center">
                  <div className="w-20 h-20 rounded-full bg-gray-200 animate-pulse"></div>
                  <div className="w-20 h-4 mt-2 rounded-full bg-gray-200 animate-pulse"></div>
                  <p className="font-sans text-sm text-gray-400 mt-4">Loading...</p>
                </div>
              ) : (
                <>
                  {
                    success ?
                    <>
                      {
                        logo ?
                        <img src={logo} alt="logo" className="h-16 mb-5" /> : null
                      }
                      <h1 className="font-sans font-bold text-3xl mb-7">ADD IT TO YOUR DIGITAL WALLET.</h1>
                      <p className="font-sans text-center text-gray-500">
                        Hey{customer.firstName ? ' ' + customer.firstName : ''}, thanks for signing up for {title}! You can download your pass below.
                      </p>
                      <div className="flex flex-col md:flex-row justify-center items-center mt-10 mb-10 gap-5">
                        <a onClick={downloadApple}>
                          <img src={DownloadFromApple} alt="Download from Apple" className="h-14" />
                        </a>
                        <a onClick={downloadGoogle}>
                          <img src={DownloadFromGoogle} alt="Download from Google" className="h-14" />
                        </a>
                      </div>
                      {
                        pleaseWait ?
                        <div className="flex flex-col justify-center items-center mt-0 mb-5">
                          <p className="text-center text-red-400 text-md mb-2">Please allow pop-ups so that you can be redirected to your download!</p>
                          <p className="text-center text-gray-400 text-xs">Please wait...</p>
                        </div> : null
                      }
                      <p className="text-center text-gray-400 text-xs mt-5">Not downloading? Please try again later. If you are still having trouble, please contact <a className="text-rose-400 hover:text-rose-700 " href="mailto:hello@walletpass.co.uk">hello@walletpass.co.uk</a></p>
                    </> :
                    <>
                      <h1 className="font-sans font-bold text-3xl mb-7">OOPS! SOMETHING WENT WRONG.</h1>
                      <p className="font-sans text-center text-gray-500">
                        Hey{customer.firstName ? ' ' + customer.firstName : ''}, thanks for signing up for {title}! You can download your pass below.
                      </p>
                    </>
                  }
                  <div className="flex flex-row justify-center items-center mt-7">
                    <p className="text-gray-400 text-xs">Powered by</p>
                    <a href="https://walletpass.co.uk" target="_blank">
                      <img
                        src={logoImage}
                        alt="Wallet Pass"
                        className="h-5 ml-2"
                      />
                    </a>
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
    </main>
  </>
  );
}
