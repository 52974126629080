import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import logoImage from '../../assets/branding/logo.png';
import { DefaultFullWidthButton } from '../../components/Buttons';
import { DateInput, DefaultInput, EmailInput, TelephoneInput } from '../../components/Inputs';
import { validateEmail } from '../../lib/validateInput';
import { Input } from '../../models/SignUpForm';

import ReactGA from "react-ga4";

export default function FormAccountInfoPage() {

  const navigate = useNavigate();
  const { passParamId } = useParams();

  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [showFirstName, setShowFirstName] = useState<boolean>(false);
  const [showLastName, setShowLastName] = useState<boolean>(false);
  const [showDOB, setShowDOB] = useState<boolean>(false);
  const [showCompanyName, setShowCompanyName] = useState<boolean>(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState<boolean>(false);
  const [showTitle, setShowTitle] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>('');
  const [inputs, setInputs] = useState<Input[]>([]);
  const [status, setStatus] = useState<'active' | 'draft' | ''>('');
  const [title, setTitle] = useState<string>('');

  const [companyName, setCompanyName] = useState<string>('');
  const [authentication, setAuthentication] = useState<{ label: string, validator: string | null }>({
    label: '',
    validator: null
  })
  const [secondaryAuthentication, setSecondaryAuthentication] = useState<{ label: string, validator: string | null }>();

  const [accountInfoRequiredInputs, setAccountInfoRequiredInputs] = useState<string[]>([]);
  
  const [uploadImageRequired, setUploadImageRequired] = useState<boolean>(false);


  useEffect(() => {
    if (!passParamId) {
      navigate('/');
    }

    if (!state) {
      navigate(`/passes/${passParamId}`);
    } else {

      ReactGA.event({
        category: "issuing",
        action: "view-account-info-form"
      });

      const { stateAuthentication, stateCompanyName, stateInputs, stateLogo, stateSecondaryAuthentication, stateStatus, stateTitle, stateAccountInfoInputs, stateUploadImageRequired } = state as any;
  
      console.log('state', state)

      if (stateSecondaryAuthentication) {
        setSecondaryAuthentication(stateSecondaryAuthentication);
      }
  
      setLogo(stateLogo);
      setInputs(stateInputs)
      setAuthentication(stateAuthentication);
      setCompanyName(stateCompanyName);
      setSecondaryAuthentication(stateSecondaryAuthentication);
      setStatus(stateStatus);
      setTitle(stateTitle);
  
      setAccountInfoRequiredInputs(stateAccountInfoInputs);
  
      // which inputs to show
  
      // does inputs contain a First Name input?
  
      const firstNameInput = stateAccountInfoInputs.find((accountInfoRequiredInput: string) => accountInfoRequiredInput === 'First Name');
  
      if (firstNameInput) {
        setShowFirstName(true);
      }
  
      // does inputs contain a Last Name input?
  
      const lastNameInput = stateAccountInfoInputs.find((accountInfoRequiredInput: string) => accountInfoRequiredInput === 'Last Name');
  
      if (lastNameInput) {
        setShowLastName(true);
      }
  
      // does inputs contain a Date of Birth input?
  
      const dobInput = stateAccountInfoInputs.find((accountInfoRequiredInput: string) => accountInfoRequiredInput === 'Date of Birth');
  
      if (dobInput) {
        setShowDOB(true);
      }
  
      // does inputs contain a Company Name input?
  
      const companyNameInput = stateAccountInfoInputs.find((accountInfoRequiredInput: string) => accountInfoRequiredInput === 'Company Name');
  
      if (companyNameInput) {
        setShowCompanyName(true);
      }
  
      // does inputs contain a Phone Number input?
  
      const phoneNumberInput = stateAccountInfoInputs.find((accountInfoRequiredInput: string) => accountInfoRequiredInput === 'Phone Number');
  
      if (phoneNumberInput) {
        setShowPhoneNumber(true);
      }
  
      // does inputs contain a Title input?
  
      const titleInput = stateAccountInfoInputs.find((accountInfoRequiredInput: string) => accountInfoRequiredInput === 'Title');
  
      if (titleInput) {
        setShowTitle(true);
      }

      if (stateUploadImageRequired) {
        setUploadImageRequired(true);
      }
  
      setIsLoading(false);
    }


  }, [])

  const [authenticationInputs, setAuthenticationInputs] = useState<{ emailAddress: string, secondaryEmailAddress: string }>({
    emailAddress: '',
    secondaryEmailAddress: ''
  })

  const [accountInfoInputs, setAccountInfoInputs] = useState<{ firstName: string, lastName: string, dateOfBirth: string, companyName: string, phoneNumber: string, title: string }>({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    companyName: '',
    phoneNumber: '',
    title: '',
  })

  const [policiesCheckbox, setPoliciesCheckbox] = useState<"checked" | "disabled">("disabled");

  const areInputsValid = () => {
    // check email, secondary email, date of birth, first name, last name
  
    if (!validateEmail(authenticationInputs.emailAddress)) {
      console.log('email address is not valid')
      return false;
    }

    if (authentication?.validator) {
      // does auth input contain the validator?
      if (!authenticationInputs.emailAddress.includes(authentication.validator)) {
        console.log('authentication.validator', authentication.validator)
        return false;
      }
    }   

    if (secondaryAuthentication && !validateEmail(authenticationInputs.secondaryEmailAddress)) {
      console.log('secondary email address is not valid')
      return false;
    }

    if (showFirstName && accountInfoInputs.firstName.length === 0) {
      console.log('first name is not valid')
      return false;
    }

    if (showLastName && accountInfoInputs.lastName.length === 0) {
      console.log('last name is not valid')
      return false;
    }
    
    if (showDOB && (accountInfoInputs.dateOfBirth.length === 0 || new Date(accountInfoInputs.dateOfBirth) === null)) {
      
      return false;
    }

    if (showCompanyName && accountInfoInputs.companyName.length === 0) {
      return false;
    }

    if (showPhoneNumber && accountInfoInputs.phoneNumber.length === 0) {
      return false;
    }

    if ((inputs.length === 0 && !uploadImageRequired) && policiesCheckbox === "disabled") {
      return false;
    }

    return true;

  }

  const handleNext = () => {

    if (!areInputsValid()) {
      alert('Please fill out all required fields');
      return;
    }

    if (inputs.length > 0) {
      navigate(`/passes/${passParamId}/additional-info`, {
        state: {
          stateAuthentication: authentication,
          stateCompanyName: companyName,
          stateInputs: inputs,
          stateLogo: logo,
          stateSecondaryAuthentication: secondaryAuthentication,
          stateStatus: status,
          stateTitle: title,
          stateAccountInfoInputs: accountInfoInputs,
          stateAuthenticationInputs: authenticationInputs,
        }
      });
    } else if (uploadImageRequired) {
      navigate(`/passes/${passParamId}/upload-image`, {
        state: {
          stateAuthentication: authenticationInputs,
          stateInputs: inputs,
          stateLogo: logo,
          stateTitle: title,
          stateAccountInfoInputs: accountInfoInputs,
          stateAuthenticationInputs: authenticationInputs,
          stateAdditionalQuestions: [],
        }
      });
    } else {

      setSubmitting(true);

      const mappedFields = inputs.map(input => {
        return {
          id: input.id,
          type: input.type,
          label: input.label,
          value: input.value,
        }
      })
  
      const body = {
        passId: passParamId,
        authentication: {
          emailAddress: authenticationInputs.emailAddress,
          secondaryEmailAddress: authenticationInputs.secondaryEmailAddress,
        },
        accountInfo: {
          firstName: accountInfoInputs.firstName,
          lastName: accountInfoInputs.lastName,
          dateOfBirth: accountInfoInputs.dateOfBirth,
          companyName: accountInfoInputs.companyName,
          phoneNumber: accountInfoInputs.phoneNumber,
          title: accountInfoInputs.title,
        },
        formFields: mappedFields,
        policiesAccepted: true,
        imageUpload: true,
      }
  
      axios.post(process.env.REACT_APP_API_URL + 'issuing/customer-sign-up', body, {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        }
      }).then(response => {
  
        setSubmitting(false);
        setSuccess(true);
  
        ReactGA.event({
          category: "issuing",
          action: "sign-up-submitted"
        });
  
        navigate('/passes/' + passParamId + '/sign-up-success', {
          state: {
            email: authenticationInputs.emailAddress,
            logo: logo,
          }
        });
  
  
      }).catch(error => {
  
        setSubmitting(false)
        try {
  
          ReactGA.event({
            category: "issuing",
            action: "sign-up-failed"
          });
          
          alert(error.response.data.message)
        }
        catch(error) {
          alert('Something went wrong')
        }
      })
    }


  }

  return (
    <>
      <main>
        {/* full screen container with image background tailwind */}
        <div className="relative overflow-hidden w-screen min-h-screen bg-gradient-to-r from-violet-500 to-red-400">
          <div className="sm:max-w-md mx-auto min-h-screen flex items-center justify-center">
            <div className="w-screen md:w-full h-screen sm:h-auto bg-white px-5 pb-11 pt-11 text-center rounded-none sm:rounded-3xl flex flex-col items-center justify-start sm:justify-center mb-5 sm:mb-0 drop-shadow-lg">
            {
                isLoading ? (
                  <div className="flex flex-col items-center justify-center">
                    <div className="w-20 h-20 rounded-full bg-gray-200 animate-pulse"></div>
                    <div className="w-20 h-4 mt-2 rounded-full bg-gray-200 animate-pulse"></div>
                    <p className="font-sans text-sm text-gray-400 mt-4">Loading...</p>
                  </div>
                ) : (
                  <>
                  {
                    logo ?
                    <img src={logo} alt="logo" className="h-16 mb-5" /> : null
                  }
                  <h1 className="font-sans font-bold text-3xl mb-7">SIGN UP.</h1>
                  {
                    showTitle ?
                    <DefaultInput className="w-full" label="Title*" placeholder='Mr' value={accountInfoInputs.title} onChangeText={(change: string) => setAccountInfoInputs({
                      ...accountInfoInputs,
                      title: change
                    })} /> : null
                  }
                  <div className="flex flex-row w-full gap-5">
                    {
                      showFirstName ?
                      <DefaultInput className="w-full" label="First Name*" placeholder='Pete' onChangeText={(change: string) => setAccountInfoInputs({
                        ...accountInfoInputs,
                        firstName: change
                      })} /> : null
                    }
                    {
                      showLastName ?
                      <DefaultInput className="w-full" label="Last Name*" placeholder='Wicks' onChangeText={(change: string) => setAccountInfoInputs({
                        ...accountInfoInputs,
                        lastName: change
                      })} /> : null
                    }
                  </div>
                  <EmailInput className="w-full" label={`${authentication?.label}*${authentication?.validator ? ' - (must include: ' + authentication?.validator + ')' : ''}`} placeholder="email@example.com" value={authenticationInputs.emailAddress} onChangeText={(change: string) => setAuthenticationInputs({
                    ...authenticationInputs,
                    emailAddress: change
                  })} />
                  {
                    secondaryAuthentication ?
                    <EmailInput className="w-full" label="Secondary Email Address*" placeholder='personal@email.com' value={authenticationInputs.secondaryEmailAddress} onChangeText={(change: string) => setAuthenticationInputs({
                      ...authenticationInputs,
                      secondaryEmailAddress: change
                    })} /> : null
                  }
                  {
                    showPhoneNumber ?
                    <TelephoneInput className="w-full" label="Phone Number*" placeholder="+44XXXXXXXXXX" onChangeText={(change: string) => setAccountInfoInputs({
                        ...accountInfoInputs,
                        phoneNumber: change
                      })} /> : null
                  }
                  {
                    showDOB ?
                    <DateInput className="w-full" label="Birthday*" onChangeText={(change: string) => setAccountInfoInputs({
                        ...accountInfoInputs,
                        dateOfBirth: change
                      })} /> : null
                  }
                  {
                    showCompanyName ?
                    <DefaultInput className="w-full" label="Company Name*" placeholder='Acme Inc' onChangeText={(change: string) => setAccountInfoInputs({
                        ...accountInfoInputs,
                        companyName: change
                      })} /> : null
                  }
                  {
                    inputs.length === 0 && !uploadImageRequired ?
                    <div className="form-check mb-5 flex flex-row">
                      <input
                        className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-gray-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        id="flexCheckDefault"
                        onChange={(change: any) => {
                          setPoliciesCheckbox(
                            change.target.checked === true ? "checked" : "disabled"
                          );
                        }}
                      />
                      <label className="form-check-label inline-block font-sans text-sm text-gray-400 text-left">
                        I accept the{" "}
                        <a href="https://walletpass.co.uk/terms-conditions" target="_blank" className="underline">
                          terms & conditions
                        </a>{" "}
                        and{" "}
                        <a href="https://walletpass.co.uk/privacy-policy" target="_blank" className="underline">
                          privacy policies
                        </a>
                        .
                      </label>
                    </div> : null
                  }
                  <DefaultFullWidthButton
                    className="w-full"
                    onPress={handleNext}
                    text={submitting ? "Submitting..." : (inputs.length === 0 && !uploadImageRequired ? "Finish Sign Up" : "Continue")}
                    disabled={submitting}
                  />
                  <div className="flex flex-row justify-center items-center mt-7">
                    <p className="text-gray-400 text-xs">Powered by</p>
                    <a href="https://walletpass.co.uk" target="_blank">
                      <img
                        src={logoImage}
                        alt="Wallet Pass"
                        className="h-5 ml-2"
                      />
                    </a>
                  </div>
                </>
                )
              }
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
