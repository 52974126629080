interface DefaultSelectProps {
  value?: string,
  label?: string,
  placeholder: string,
  onSelect?: any,
  options: string[],
  className?: string
}

export function DefaultSelect(props: DefaultSelectProps) {

  return (
    <div className={props.className}>
      <label className="relative block mb-5 text-left">
        {
          props.label ?
          <span className="ml-3 text-sm text-gray-600">{ props.label }</span> : null
        }
        <select
          className="mt-1 placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md p-3 shadow-sm text-gray-900 focus:outline-none focus:border-gray-500 focus:ring-gray-500 focus:ring-1 sm:text-sm"
          name="select"
          onChange={(change: any) => { props.onSelect(change.target.value) }}
          // value={props.value}
        >
          {
            props.placeholder ?
            <option value="" selected={!props.value ? true : false} disabled>{props.placeholder}</option> : null
          }
          {
            props.options.map((option: string, index: number) => {
              return <option key={index} value={option}>{ option }</option>
            })
          }
        </select>
      </label>
    </div>
  )
}
