import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ReactGA from "react-ga4";
import DownloadPage from './pages/download';
import FormHomePage from './pages/sign-up/home';
import FormAccountInfoPage from './pages/sign-up/account-info';
import FormAdditionalQuestionsPage from './pages/sign-up/additional-questions';
import FormCompletePage from './pages/sign-up/complete';
import FormUploadImagePage from './pages/sign-up/upload-image';

function App() {

  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID as string);

  return (
    <BrowserRouter>
        <Routes>
          <Route path="passes" >
            <Route path=":passParamId">
              <Route path="" element={<FormHomePage />} />
              <Route path="account-info" element={<FormAccountInfoPage />} />
              <Route path="additional-info" element={<FormAdditionalQuestionsPage />} />
              <Route path="upload-image" element={<FormUploadImagePage />} />
              <Route path="sign-up-success" element={<FormCompletePage />} />
            </Route>
          </Route>
          <Route path="download" element={<DownloadPage />} />
        </Routes> 
    </BrowserRouter>
  );
}

export default App;
